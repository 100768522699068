import { Layout } from 'components/global'
import LegalBody from 'components/shared/legalBody'
import SubHeader from 'components/shared/subHeader'
import React from 'react'

const TermsConditions = () => (
  <Layout>
    <SubHeader />
    <LegalBody>
      <h1>Media Accreditations</h1>
      <ul className="media">
        <li>The accreditation pass is non-transferable;</li>
        <li>
          The accreditation pass must be displayed in a clear and visible
          manner;
        </li>
        <li>
          You shall comply with any and all applicable COVID-19 guidance,
          security rules and Ground Regulations;
        </li>
        <li>
          A valid match ticket is required for entry into the venue seating
          bowl;
        </li>
        <li>
          You consent to be filmed or photographed and grant the Tournament
          Organiser&rsquo;s the right to use any such images and/or footage at
          its discretion without compensation;
        </li>
        <li>
          You shall not, nor permit, any television or radio antenna, sign,
          advertisement, notice or other fixtures to be placed upon any part of
          the Venue(s) without the prior written consent of Tournament
          Organisers;
        </li>
        <li>
          Recording and/or transmitting any video footage from within the
          Venue(s) is not permitted without the prior written consent of
          Tournament Organisers; and
        </li>
        <li>
          Entry may be refused or withdrawn at the Tournament Organiser&rsquo;s
          discretion;
        </li>
        <li>
          Access to any media areas or mixed zones will be at the discretion of
          the organisers and or stadium staff
        </li>
      </ul>
    </LegalBody>
  </Layout>
)

export default TermsConditions
