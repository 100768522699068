import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Logo from 'svgs/cup-logo.svg'
import { media } from 'utils/media'

const SubHeaderStyles = styled.header`
  width: 100%;
  section {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10rem;
      max-width: 15rem;
    }
    @media ${media.md} {
      padding: 5.5rem 0;
    }
  }
`
const SubHeader = () => {
  const i = true
  return (
    <SubHeaderStyles>
      <section>
        <Link to="/">
          <Logo />
        </Link>
      </section>
    </SubHeaderStyles>
  )
}

export default SubHeader
